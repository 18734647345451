<template>
  <v-form :disabled="isLoading">
    <v-card flat color="accent" :loading="isLoading ? 'primary' : null">
      <v-card-text :class="{ 'black--text': isThemeLight }">
        <div class="text-h6 mb-3">Account Information</div>

        <v-row>
          <!-- User's name -->
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.trim="formData.name"
              :hide-details="!$v.formData.name.$anyError"
              :error-messages="$v.formData.name.$anyError ? ['User name is required'] : null"
              @blur="$v.formData.name.$touch"
              autocomplete="admin-user-name"
              label="Name"
              outlined
              dense
            />
          </v-col>

          <!-- User's email -->
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.trim="formData.email"
              :hide-details="!$v.formData.email.$anyError"
              :error-messages="$v.formData.email.$anyError ? ['Please enter a correct email address'] : null"
              @blur="$v.formData.email.$touch"
              autocomplete="admin-user-email"
              label="Email"
              outlined
              dense
            />
          </v-col>

          <!-- User's mobile phone -->
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.trim="formData.phone"
              :hide-details="!$v.formData.phone.$anyError"
              :error-messages="$v.formData.phone.$anyError ? ['Please enter a correct phone number'] : null"
              @blur="$v.formData.phone.$touch"
              autocomplete="admin-user-phone"
              label="Mobile"
              outlined
              dense
            />
          </v-col>

          <!-- User's mobile phone -->
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.trim="formData.password"
              :hide-details="!$v.formData.password.$anyError"
              :error-messages="$v.formData.password.$anyError ? ['Please enter a valid password'] : null"
              @blur="$v.formData.password.$touch"
              autocomplete="admin-user-password"
              prepend-inner-icon="shuffle"
              :append-icon="shouldPasswordBeVisible ? 'visibility' : 'visibility_off'"
              :type="shouldPasswordBeVisible ? 'text' : 'password'"
              @click:append="shouldPasswordBeVisible = !shouldPasswordBeVisible"
              @click:prepend-inner="generateRandomPassword"
              label="Password"
              outlined
              counter
              dense
            />
          </v-col>

          <!-- User's country/currency select box -->
          <v-col cols="12" sm="6" :lg="isTeamView ? 4 : 6">
            <v-select
              v-model="formData.country"
              :items="countryOptions"
              :hide-details="!$v.formData.country.$anyError"
              :error-messages="$v.formData.country.$anyError ? ['Please enter a correct country'] : null"
              @blur="$v.formData.country.$touch"
              label="Country/Currency"
              item-value="countryCode"
              item-text="name"
              outlined
              dense
            >
              <!-- show user options list -->
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item ripple v-bind="attrs" v-on="on">
                  <v-list-item-action class="mr-4">
                    <v-img
                      height="13"
                      width="20"
                      :src="proxyUrl(`https://flagcdn.com/w20/${item.countryCode.toLowerCase()}.png`, true)"
                    ></v-img>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title> {{ item.name }} - {{ item.currencyCode }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <!-- show the selected option -->
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center">
                  <v-img height="13" width="20" :src="proxyUrl(`https://flagcdn.com/w20/${item.countryCode.toLowerCase()}.png`, true)" />
                  <span class="ml-4"> {{ item.name }} - {{ item.currencyCode }} </span>
                </div>
              </template>
            </v-select>
          </v-col>

          <!-- User's role -->
          <v-col v-if="!isTeamView" cols="12" sm="6">
            <v-select
              v-model="formData.role"
              :items="userRoleOptions"
              :hide-details="!$v.formData.role.$anyError"
              :error-messages="$v.formData.role.$anyError ? ['Please select a role'] : null"
              @blur="$v.formData.role.$touch"
              label="Role"
              outlined
              dense
            />
          </v-col>

          <!-- User's status -->
          <v-col cols="12" sm="6" :lg="isTeamView ? 4 : 3">
            <v-select
              v-model="formData.status"
              :items="booleanOptions"
              :hide-details="!$v.formData.status.$anyError"
              :error-messages="$v.formData.status.$anyError ? ['Please select a status'] : null"
              @blur="$v.formData.status.$touch"
              label="Status"
              outlined
              dense
            />
          </v-col>

          <!-- Whether this user can upload logo and add team members -->
          <template v-if="!isTeamView">
            <v-col cols="12" sm="6" :lg="isTeamView ? 4 : 3">
              <v-select
                v-model="formData.isWhitelisted"
                :items="polarOptions"
                :hide-details="!$v.formData.isWhitelisted.$anyError"
                :error-messages="$v.formData.isWhitelisted.$anyError ? ['Please select a value'] : null"
                @blur="$v.formData.isWhitelisted.$touch"
                label="Is Whitelisted?"
                outlined
                dense
              />
            </v-col>

            <!-- Whether this user is marked as paid user or not -->
            <template v-if="!isTeamView">
              <v-col cols="12" sm="6" :lg="isTeamView ? 4 : 3">
                <v-select
                  v-model="formData.isDemo"
                  :items="demoOptions"
                  :hide-details="!$v.formData.isDemo.$anyError"
                  :error-messages="$v.formData.isDemo.$anyError ? ['Please select a value'] : null"
                  @blur="$v.formData.isDemo.$touch"
                  label="Is Demo Account?"
                  outlined
                  dense
                />
              </v-col>
            </template>

            <!-- To associate user with a company/price string -->
            <v-col cols="12" sm="6" :lg="isTeamView ? 4 : 3">
              <v-select v-model="formData.companyId" :items="companyOptions" label="Price String" hide-details outlined dense />
            </v-col>
          </template>

          <!-- For Date Range about user's validity -->
          <!-- Only show for user type user -->
          <v-col v-if="formData.role === constants.model.user.roles.endUser" cols="12" sm="6" :lg="isTeamView ? 4 : 6">
            <date-range-picker
              v-model="formData.validity"
              :hide-details="!$v.formData.validity.$anyError"
              :error-messages="$v.formData.validity.$anyError ? ['Please select a date range'] : null"
              @blur="$v.formData.validity.$touch"
              label="Account Validity"
            />
          </v-col>
        </v-row>

        <v-divider class="mt-6 mb-4" />

        <div class="text-h6 mb-3">Allowed Services</div>

        <v-row>
          <v-col v-for="item in allowedServices" :key="'allowed-service-' + item.name" cols="12" sm="6" md="4">
            <v-select v-model="item.status" :items="booleanOptions" :label="item.text" hide-details outlined dense />
          </v-col>
        </v-row>

        <v-divider class="mt-6 mb-4" />

        <!-- Allow editing the actions for each user -->
        <template v-if="allowedActions.length">
          <div class="text-h6 mb-3">Allowed Actions</div>

          <v-row>
            <v-col v-for="item in allowedActions" :key="'allowed-action-' + item.name" cols="12" sm="6" md="4">
              <v-select v-model="item.status" :items="booleanOptions" :label="item.text" hide-details outlined dense />
            </v-col>
          </v-row>

          <v-divider class="mt-6 mb-4" />
        </template>

        <!-- Allow editing the filters for each user only by admin -->
        <template v-if="allowedFilters.length && !isTeamView">
          <div class="text-h6 mb-3">Allowed Filters</div>

          <v-row>
            <v-col v-for="item in allowedFilters" :key="'allowed-filter-' + item.name" cols="12" sm="6" md="4">
              <v-select v-model="item.status" :items="booleanOptions" :label="item.text" hide-details outlined dense />
            </v-col>
          </v-row>

          <v-divider class="mt-6 mb-4" />
        </template>

        <!-- Allow editing the platforms for each user only by admin -->
        <template v-if="allowedPlatforms.length && !isTeamView">
          <div class="text-h6 mb-3">Allowed Platforms</div>

          <v-row>
            <v-col v-for="item in allowedPlatforms" :key="'allowed-filter-' + item.name" cols="12" sm="6" md="4">
              <v-select v-model="item.status" :items="booleanOptions" :label="item.text" hide-details outlined dense />
            </v-col>
          </v-row>

          <v-divider class="mt-6 mb-4" />
        </template>

        <!-- Allow editing the meta values for each user only by admin -->
        <template v-if="!isTeamView">
          <div class="text-h6 mb-3">User Metas</div>

          <v-row>
            <v-col v-for="item in formData.metas" :key="'meta-input-' + item.key" cols="12" sm="6" md="4">
              <v-text-field v-model="item.value" :label="item.text" :type="item.type" hide-details outlined dense />
            </v-col>
          </v-row>

          <v-divider class="mt-6 mb-4" />
        </template>

        <template v-if="!isTeamView">
          <div class="text-h6 mb-3">API Management</div>

          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-select v-model="formData.canUseAPI" :items="booleanOptions" label="Can Use API" hide-details outlined dense />
            </v-col>

            <v-col cols="12" sm="6" md="8">
              <v-combobox
                v-model="formData.IPAddresses"
                label="Whitelisted IP Addresses"
                :allow-overflow="false"
                placeholder="Use enter to save value"
                multiple
                outlined
                dense
                small-chips
                deletable-chips
                :hide-details="!$v.formData.IPAddresses.$anyError"
                :error-messages="$v.formData.IPAddresses.$anyError ? ['Please enter valid IP values'] : null"
                @input="$v.formData.IPAddresses.$touch"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-divider class="mt-6 mb-4" />
        </template>

        <div class="d-flex items-center justify-space-between mb-6">
          <div class="width-100">
            <div class="mb-3 d-flex justify-space-between">
              <span class="text-h6">
                Manage Credits
              </span>

              <!-- Hide from team view -->
              <v-select
                v-if="!isTeamView"
                v-model="formData.creditType"
                :items="userCreditTypeOptions"
                style="max-width: 10em"
                label="Credit Type"
                hide-details
                outlined
                dense
              />
            </div>

            <!-- Show in team view -->
            <template v-if="isTeamView">
              <div class="text-subtitle-2">
                Your Balance:&nbsp; <b>{{ authUser.balance / constants.conversionRate }}</b>
              </div>

              <div v-if="formData.creditType === constants.model.user.creditTypes.split" class="mb-3">
                Influencer Discovery:&nbsp; <b>{{ authUser.dividedBalance["influencer-discovery"] / constants.conversionRate }}</b>,&nbsp;
                Influencer Insight:&nbsp; <b>{{ authUser.dividedBalance["influencer-insight"] / constants.conversionRate }}</b>
              </div>
            </template>

            <!-- Show if the user is being edited -->
            <template v-if="userData">
              <div class="text-subtitle-2">
                Current User Balance:&nbsp; <b>{{ userData.balance / constants.conversionRate }}</b>
              </div>

              <div v-if="formData.creditType === constants.model.user.creditTypes.split" class="mb-2">
                Influencer Discovery:&nbsp; <b>{{ userData.dividedBalance["influencer-discovery"] / constants.conversionRate }}</b>, &nbsp;
                Influencer Insight:&nbsp; <b>{{ userData.dividedBalance["influencer-insight"] / constants.conversionRate }}</b>
              </div>
            </template>

            <!-- Show the total values -->
            <div class="text-subtitle-2">
              Updated User Balance:&nbsp; <b>{{ updatedUserBalance.total / constants.conversionRate }}</b>
            </div>

            <div v-if="formData.creditType === constants.model.user.creditTypes.split">
              Influencer Discovery:&nbsp; <b>{{ updatedUserBalance["influencer-discovery"] / constants.conversionRate }}</b>, &nbsp;
              Influencer Insight:&nbsp; <b>{{ updatedUserBalance["influencer-insight"] / constants.conversionRate }}</b>
            </div>
          </div>
        </div>

        <v-row
          v-for="transaction in possibleTransactions"
          :key="transaction.for"
        >
          <v-col cols="12" sm="6" md="2">
            <v-select
              v-model="transaction.type"
              :items="transactionTypeOptions"
              label="Type"
              hide-details
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <!-- filtering to disable select other options -->
            <v-text-field
              label="For"
              :value="transaction.text"
              hide-details
              readonly
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="6" md="2">
            <v-text-field
              v-model.trim="transaction.amount"
              hide-details
              :rules="[(v) => (parseInt(v, 10) < 0 ? 'Amount must be positive number' : true)]"
              label="Amount"
              type="number"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field v-model.trim="transaction.comment" hide-details label="Comment" outlined dense />
          </v-col>
        </v-row>

        <div class="text-h6 mt-4 mt-md-8 mb-3">
          Module Usage
        </div>

        <div
          v-for="transaction in moduleTransactions"
          :key="transaction.for"
          class="mb-6"
        >
          <div class="mb-2">
            <template v-if="isTeamView">
              Your Value: <b>{{ authUser.dividedBalance[transaction.for] / constants.conversionRate }}</b>,
              &nbsp;&nbsp;
            </template>
            Current Value:
              <b v-if="userData">
                {{ userData.dividedBalance[transaction.for] / constants.conversionRate }}
              </b>
              <b v-else>
                0
              </b>,
            &nbsp;&nbsp;
            Updated Value: <b>{{ updatedUserBalance[transaction.for] / constants.conversionRate }}</b>
          </div>

          <v-row>
            <v-col cols="12" sm="6" md="2">
              <v-select
                v-model="transaction.type"
                :items="transactionTypeOptions"
                label="Type"
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <!-- filtering to disable select other options -->
              <v-text-field
                label="For"
                :value="transaction.text"
                hide-details
                readonly
                outlined
                dense
              />
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-text-field
                v-model.trim="transaction.amount"
                hide-details
                :rules="[(v) => (parseInt(v, 10) < 0 ? 'Amount must be positive number' : true)]"
                label="Amount"
                type="number"
                outlined
                dense
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model.trim="transaction.comment" hide-details label="Comment" outlined dense />
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          depressed
          color="primary"
          @click="handleSubmit"
          :loading="isLoading"
          :disabled="isLoading"
          :class="{ 'shadow--primary': !isLoading }"
        >
          Save User
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
// Import helper validation functions
import subnetValidator from "@/helpers/validators/subnetValidator"
import { required, email, decimal, ipAddress } from "vuelidate/lib/validators"

// Import children component
const DateRangePicker = () => import(/* webpackChunkName: "date-range-picker" */ "@/blocks/common/form/DateRangePicker.vue")

// The default transaction data
const initialTransactionData = (forValue, textValue) => ({
  type: constants.model.transaction.type.credit,
  comment: "",
  amount: "",
  text: textValue,
  for: forValue
})

const initialFormData = () => ({
  name: "",
  email: "",
  phone: "",
  password: "",
  country: "",
  currency: "",
  role: constants.model.user.roles.endUser,
  creditType: constants.model.user.creditTypes.unified,
  status: true,
  isDemo: true,
  isWhitelisted: false,
  companyId: null,
  validity: null,
  canUseAPI: false,
  IPAddresses: [],
  allowedServices: [
    {
      name: constants.model.user.allowedServices.influencerDiscovery,
      text: "Influencer Discovery",
      status: true
    },
    {
      name: constants.model.user.allowedServices.influencerInsight,
      text: "Influencer Insight",
      status: true
    },
    {
      name: constants.model.user.allowedServices.paidCollaborations,
      text: "Paid Collaborations",
      status: true
    },
    {
      name: constants.model.user.allowedServices.audienceOverlap,
      text: "Audience Overlap",
      status: true
    },
    {
      name: constants.model.user.allowedServices.influencerTieBreaker,
      text: "Influencer Tie Breaker",
      status: true
    },
    {
      name: constants.model.user.allowedServices.paidComparison,
      text: "Paid Comparison",
      status: true
    },
    {
      name: constants.model.user.allowedServices.socialSentiments,
      text: "Social Sentiments",
      status: false
    },
    {
      name: constants.model.user.allowedServices.competitorCheck,
      text: "Competitor Check",
      status: false
    },
    {
      name: constants.model.user.allowedServices.campaignPlanning,
      text: "Campaign Planning",
      status: false
    },
    {
      name: constants.model.user.allowedServices.mentionTracking,
      text: "CRM - Mention Tracking",
      status: false
    },
    {
      name: constants.model.user.allowedServices.campaignTracking,
      text: "CRM - Campaign Tracking",
      status: true
    },
    {
      name: constants.model.user.allowedServices.influencerGroups,
      text: "CRM - Influencer Groups",
      status: true
    },
    {
      name: constants.model.user.allowedServices.influencerOutreach,
      text: "CRM - Influencer Outreach",
      status: false
    }
  ],

  allowedActions: [
    {
      name: constants.model.user.allowedServices.actionContentDiscovery,
      text: "Content Discovery",
      status: false
    },
    {
      name: constants.model.user.allowedServices.actionMassDiscoveryDownload,
      text: "Mass Discovery Downloads",
      status: false
    },
    {
      name: constants.model.user.allowedServices.actionUnlimitedDiscoveryView,
      text: "Unlimited Discovery View",
      status: false
    },
    {
      name: constants.model.user.allowedServices.actionBulkExportInfluencerInsightsPDF,
      text: "Bulk Export Insights (PDFs)",
      status: false
    },
    {
      name: constants.model.user.allowedServices.actionBulkExportInfluencerInsightsXLSX,
      text: "Bulk Export Insights (XLSXs)",
      status: false
    }
  ],

  allowedFilters: [
    {
      name: constants.model.user.allowedServices.filterDiscoveryEthnicity,
      text: "Influencer Discovery - Ethnicity",
      status: false
    }
  ],

  allowedPlatforms: [
    {
      name: constants.model.user.allowedServices.platformDiscoveryTwitter,
      text: "Discovery Platform - Twitter",
      status: false
    },
    {
      name: constants.model.user.allowedServices.platformDiscoverySnapchat,
      text: "Discovery Platform - Snapchat",
      status: false
    },
    {
      name: constants.model.user.allowedServices.platformDiscoveryLinkedIn,
      text: "Discovery Platform - LinkedIn",
      status: true
    }
  ],

  // For the meta values
  metas: [
    {
      key: constants.model.user.metas.discoveryExportsLimit,
      type: "number",
      text: "Discovery Exports Limit",
      value: 100
    }
  ],

  // For the transactions
  transactions: {
    influencerDiscovery: initialTransactionData("influencer-discovery", "Influencer Discovery"),
    influencerInsight: initialTransactionData("influencer-insight", "Influencer Insight"),
    unifiedBalance: initialTransactionData("unified-balance", "Unified Balance"),

    audienceOverlap: initialTransactionData("audience-overlap", "Audience Overlap"),
    paidCollaboration: initialTransactionData("paid-collaboration", "Paid Collaborations"),

    socialSentiment: initialTransactionData("social-sentiment", "Social Sentiment"),
    competitorCheck: initialTransactionData("competitor-check", "Competitor Check"),
    contentDiscovery: initialTransactionData("content-discovery", "Content Discovery"),

    // influencerGroup: initialTransactionData("influencer-group", "Influencer Group"),
    campaignTracking: initialTransactionData("campaign-tracking", "Campaign Tracking"),
    mentionTracking: initialTransactionData("mention-tracking", "Mention Tracking"),
    influencerOutreach: initialTransactionData("influencer-outreach", "Influencer Outreach"),
  }
})

// Export the SFC
export default {
  // Name of the component
  name: "UserForm",

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: false,
      default: null
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    // To hide some or most of the options from non-admin user form
    isTeamView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Register children components
  components: {
    DateRangePicker
  },

  // Define local data variables
  data: () => ({
    shouldPasswordBeVisible: false,

    formData: initialFormData(),

    companyOptions: [],

    userRoleOptions: [
      {
        text: "Admin",
        value: constants.model.user.roles.admin
      },
      {
        text: "Moderator",
        value: constants.model.user.roles.moderator
      },
      {
        text: "User",
        value: constants.model.user.roles.endUser
      }
    ],

    userCreditTypeOptions: [
      {
        text: "Split",
        value: constants.model.user.creditTypes.split
      },
      {
        text: "Unified",
        value: constants.model.user.creditTypes.unified
      }
    ],

    booleanOptions: [
      {
        text: "Active",
        value: true
      },
      {
        text: "Inactive",
        value: false
      }
    ],

    polarOptions: [
      {
        text: "Yes",
        value: true
      },
      {
        text: "No",
        value: false
      }
    ],

    demoOptions: [
      {
        text: "Demo",
        value: true
      },
      {
        text: "Paid",
        value: false
      }
    ],

    transactionTypeOptions: [
      {
        text: "Credit",
        value: constants.model.transaction.type.credit
      },
      {
        text: "Debit",
        value: constants.model.transaction.type.debit
      }
    ],

    countryOptions: [
      {
        name: "India",
        currencyCode: "INR",
        countryCode: "in"
      },
      {
        name: "Malaysia",
        currencyCode: "MYR",
        countryCode: "my"
      },
      {
        name: "Singapore",
        currencyCode: "SGD",
        countryCode: "sg"
      },
      {
        name: "Indonesia",
        currencyCode: "IDR",
        countryCode: "id"
      },
      {
        name: "Vietnam",
        currencyCode: "VND",
        countryCode: "vn"
      },
      {
        name: "U. A. Emirates",
        currencyCode: "AED",
        countryCode: "ae"
      }
    ]
  }),

  // Define computable properties
  computed: {
    /**
     * The possible transactions that can be made by the user
     *
     * @returns {Array}
     */
    possibleTransactions() {
      // If the credit type is unified
      if (this.formData.creditType === constants.model.user.creditTypes.unified) {
        return [
          this.formData.transactions.unifiedBalance
        ]
      }
      // Otherwise, respond with
      else {
        return [
          this.formData.transactions.influencerDiscovery,
          this.formData.transactions.influencerInsight
        ]
      }
    },

    /**
     * The possible module related transactions that can be made by the user
     *
     * @returns {Array}
     */
    moduleTransactions() {
      return [
        {
          condition: this.formData.allowedServices.find((search) => search.name === constants.model.user.allowedServices.audienceOverlap)?.status,
          value: this.formData.transactions.audienceOverlap
        },
        {
          condition: this.formData.allowedServices.find((search) => search.name === constants.model.user.allowedServices.socialSentiments)?.status,
          value: this.formData.transactions.socialSentiment
        },
        {
          condition: this.formData.allowedServices.find((search) => search.name === constants.model.user.allowedServices.competitorCheck)?.status,
          value: this.formData.transactions.competitorCheck
        },
        {
          condition: this.formData.allowedActions.find((search) => search.name === constants.model.user.allowedServices.actionContentDiscovery)?.status,
          value: this.formData.transactions.contentDiscovery
        },
        // {
        //   condition: this.formData.allowedServices.find((search) => search.name === constants.model.user.allowedServices.influencerGroups)?.status,
        //   value: this.formData.transactions.influencerGroup
        // },
        {
          condition: this.formData.allowedServices.find((search) => search.name === constants.model.user.allowedServices.paidCollaborations)?.status,
          value: this.formData.transactions.paidCollaboration
        },
        {
          condition: this.formData.allowedServices.find((search) => search.name === constants.model.user.allowedServices.campaignTracking)?.status,
          value: this.formData.transactions.campaignTracking
        },
        {
          condition: this.formData.allowedServices.find((search) => search.name === constants.model.user.allowedServices.mentionTracking)?.status,
          value: this.formData.transactions.mentionTracking
        },
        {
          condition: this.formData.allowedServices.find((search) => search.name === constants.model.user.allowedServices.influencerOutreach)?.status,
          value: this.formData.transactions.influencerOutreach
        }
      ]
      .filter((item) => item.condition)
      .map((item) => item.value)
    },

    /**
     * The current logged in user's information
     *
     * @returns {Object}
     */
    authUser() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Show what the updated balance would look like for the user
     *
     * @returns {Object}
     */
    updatedUserBalance() {
      // Compute the amount
      const map = {}

      // The updated balance amount
      map.total = this.userData?.balance || 0

      // Loop through each of the transaction keys
      for (const key of Object.keys(this.formData.transactions)) {
        // Get the transaction
        const transaction = this.formData.transactions[key]

        // Get the value from before
        const before = this.userData?.dividedBalance[transaction.for] || 0

        // Get the input value
        const value = (parseFloat(transaction.amount, 10) * constants.conversionRate) || 0

        // Update the value
        map[transaction.for] = before + (transaction.type === constants.model.transaction.type.credit ? value : -value)

        // If the key is one of the balance affecting values
        if (["influencer-discovery", "influencer-insight", "audience-overlap", "unified-balance"].includes(transaction.for)) {
          map.total += transaction.type === constants.model.transaction.type.credit ? value : -value
        }
      }

      // Return the map
      return map
    },

    /**
     * Filter out the services to be shown for the input elements
     *
     * @returns {Array}
     */
    allowedServices() {
      // In case of team view, make sure we filter out the inputs the parent doesn't have access to
      return this.formData.allowedServices.filter((item) =>
        this.isTeamView ? this.$store.getters["auth/isServiceAllowed"](item.name) : true
      )
    },

    /**
     * Filter out the actions to be shown for the input elements
     *
     * @returns {Array}
     */
    allowedActions() {
      // In case of team view, make sure we filter out the inputs the parent doesn't have access to
      return this.formData.allowedActions.filter((item) =>
        this.isTeamView ? this.$store.getters["auth/isServiceAllowed"](item.name) : true
      )
    },

    /**
     * Filter out the filters to be shown for the input elements
     *
     * @returns {Array}
     */
    allowedFilters() {
      // In case of team view, make sure we filter out the inputs the parent doesn't have access to
      return this.formData.allowedFilters.filter((item) =>
        this.isTeamView ? this.$store.getters["auth/isServiceAllowed"](item.name) : true
      )
    },

    /**
     * Filter out the platforms to be shown for the input elements
     *
     * @returns {Array}
     */
    allowedPlatforms() {
      // In case of team view, make sure we filter out the inputs the parent doesn't have access to
      return this.formData.allowedPlatforms.filter((item) =>
        this.isTeamView ? this.$store.getters["auth/isServiceAllowed"](item.name) : true
      )
    }
  },

  /**
   * The vuelidate validations object
   *
   * @returns {Object}
   */
  validations() {
    const validationsObject = {
      formData: {
        name: {
          required
        },
        email: {
          required,
          email
        },
        phone: {},
        password: {},
        country: {
          required
        },
        role: {
          required
        },
        status: {
          required
        },
        isDemo: {
          required
        },
        isWhitelisted: {
          required
        },
        validity: {},

        transactions: {

        }
      }
    }

    // Loop through each of the transactions
    for (const key of Object.keys(this.formData.transactions)) {
      validationsObject.formData.transactions[key] = {
        amount: {
          decimal,
          minValue: (value) => value >= 0
        }
      }
    }

    // if it's the form to create a model
    if (!this.userData) {
      validationsObject.formData.password.required = required
    }

    // if it's the admin view
    if (!this.isTeamView) {
      validationsObject.formData.IPAddresses = {
        $each: {
          IPorSubnet: (v) => ipAddress(v) || subnetValidator(v)
        }
      }
    }

    // only validate validity date range if user is of type end-user
    if (this.formData.role === constants.model.user.roles.endUser) {
      validationsObject.formData.validity.required = required

      // make sure that one of the values is less than the other
      validationsObject.formData.validity.isRange = (value) => {
        if (Array.isArray(value) && value.length === 2) {
          // if it's the same value, return error
          if (value[0] === value[1]) {
            return false
          }
        } else {
          return false
        }

        return true
      }
    }

    return validationsObject
  },

  // Define the methods object for this component
  methods: {
    /**
     * Handle when the submit button is clicked in the form
     *
     * @returns {void}
     */
    async handleSubmit() {
      // Clone the form data
      const formData = Object.assign({}, this.formData)

      // Check if there's any value for country
      if (formData.country) {
        // If yes, find and update the value for currency
        formData.currency = this.countryOptions.find((search) => search.countryCode === formData.country)?.currencyCode
      }

      // Check if the maximum value is intact for team manager
      if (this.isTeamView) {
        let shouldStopExecution = false

        // Loop through each transaction
        for (const key of Object.keys(formData.transactions)) {
          // Get the transaction
          const transaction = formData.transactions[key]

          // Get the value
          const value = (parseFloat(transaction.amount, 10) * constants.conversionRate) || 0

          // If the user is trying to give more credits that it has
          if (this.authUser.dividedBalance[transaction.for] < value && transaction.type === constants.model.transaction.type.credit) {
            this.$store.dispatch("toasts/add", {
              text: `You don't have enough credits to distribute "${transaction.text}"`
            })

            shouldStopExecution = true

            break
          }
        }

        // If the execution should stop, return
        if (shouldStopExecution) {
          return false
        }
      }

      // Run the validations
      await this.$v.formData.$touch()

      // Check if there's any error in the form
      if (this.$v.formData.$anyError) {
        this.$store.dispatch("toasts/add", { text: "Please check the form again!" })

        // Stop further execution
        return false
      }
      // check if the validity is correctly set
      if (formData.role !== constants.model.user.roles.endUser) {
        formData.validity = null
      }
      // else if there's some value that's selected
      else if (formData.validity !== null) {
        // sort the dates correctly
        const sortedValidity = formData.validity.sort((a, b) => dayjs(a).unix() - dayjs(b).unix())

        formData.valid_from = `${sortedValidity[0]} 00:00:00`
        formData.valid_to = `${sortedValidity[1]} 00:00:00`
      }

      // If it's not admin view, delete API and IP addresses from data
      if (this.isTeamView) {
        delete formData.canUseAPI
        delete formData.IPAddresses
      }

      // Merge allowed services and allowed actions together
      formData.allowedServices = [
        ...formData.allowedServices,
        ...formData.allowedActions,
        ...formData.allowedFilters,
        ...formData.allowedPlatforms
      ]

      // Go through each meta value and convert the value into string
      for (const meta of formData.metas) {
        meta.value = String(meta.value)
      }

      // Call submit event on parent component
      this.$emit("submit", formData)
    },

    /**
     * Make the network request and fetch list of companies for the select input
     *
     * @returns {void}
     */
    async fetchCompanyOptions() {
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        const response = await axios({ url: "/api/admin/companies" })

        this.companyOptions = [
          {
            text: "Default",
            value: null
          },
          ...response.data.map((item) => ({
            text: item.name,
            value: item.id
          }))
        ]
      } catch (error) {
        logger({ type: "Error while fetching company options", error })
      } finally {
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Use the random string generator function to generate a password
     *
     * @returns {void}
     */
    generateRandomPassword() {
      this.formData.password = generateRandomString(16)
      this.shouldPasswordBeVisible = true
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Fetch all the possible companies to be associated with
    this.fetchCompanyOptions()

    // If this is an edit form, we should have some incoming data
    if (this.userData) {
      this.formData = {
        ...initialFormData(),
        id: this.userData.id,
        name: this.userData.name,
        email: this.userData.email,
        phone: this.userData.phone,
        password: "",
        country: this.userData.country,
        currency: this.userData.currency,
        role: this.userData.role,
        status: this.userData.is_active,
        creditType: this.userData.credit_type,
        isDemo: this.userData.is_demo,
        isWhitelisted: this.userData.is_whitelisted,
        companyId: this.userData.company_id,
        canUseAPI: this.userData.can_use_api,
        IPAddresses: this.userData.ip_addresses
      }

      // If this is of type end-user, then push the validity as well
      if (this.userData.role === constants.model.user.roles.endUser) {
        this.formData.validity = [
          dayjs(this.userData.valid_from || undefined).format("YYYY-MM-DD"),
          dayjs(this.userData.valid_to || undefined).format("YYYY-MM-DD")
        ]
      }

      // Find the already existing allowed services and update their values
      for (const allowedService of this.userData.allowed_services) {
        // Find the service or action in default form
        const foundService =
          this.formData.allowedServices.find((item) => item.name === allowedService.name) ||
          this.formData.allowedPlatforms.find((item) => item.name === allowedService.name) ||
          this.formData.allowedActions.find((item) => item.name === allowedService.name) ||
          this.formData.allowedFilters.find((item) => item.name === allowedService.name)

        if (foundService) {
          foundService.status = allowedService.status
        }
      }

      // Find the already existing metas and update their values
      for (const meta of this.userData.metas) {
        // Find the meta in default form
        const foundMeta = this.formData.metas.find((item) => item.key === meta.key)

        if (foundMeta) {
          foundMeta.value = meta.value
        }
      }
    }

    // Now check if it's in team view
    if (this.isTeamView) {
      // If yes, also update the creditType value in form
      this.formData.creditType = this.authUser.credit_type
    }
  }
}
</script>
